import "./Hero01.css";
import pic01 from "../img/frog-01.webp";

function Hero01() {
  return (
    <div className="Hero01 section">
      <div className="container">
        <div className="Hero01-box">
          <img src={pic01} alt="mascot"></img>

          <div>
            <h1>$BANJO</h1>

            <a href="https://distrokid.com/hyperfollow/banjo11/i-found-frog">
              <button className="btn01">Listen to Banjo play!</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
