import "./About01.css";
import pic01 from "../img/story.webp";

import icon00 from "../img/icons/spotify.png";
import icon01 from "../img/icons/telegram.svg";
import icon02 from "../img/icons/twitter.svg";
import icon03 from "../img/icons/raydium.png";
import icon04 from "../img/icons/dextools.png";
import icon05 from "../img/icons/solscan.png";

function About01() {
  return (
    <div className="About01 section" id="about">
      <div className="container">
        <div className="About01-box">
          <img src={pic01} alt="picture" className="cartoony"></img>

          <div>
            <h5>ABOUT</h5>
            <h2>The Story</h2>
            <p>
              Back in 1979, the Muppets Show released a special where Kermit the
              Frog plays the banjo to the song— “The Rainbow Connection”.
              <br /> This began the frog musician pop culture references that
              inspire an endearing representation of carefree joy.
              <br />
              <br />
              Welcome, $BANJO.
            </p>

            <ul className="links">
              <li>
                <a
                  className="btn"
                  href="https://distrokid.com/hyperfollow/banjo11/i-found-frog"
                >
                  <img src={icon00} alt="picture"></img>
                </a>
              </li>
              <li>
                <a className="btn" href="https://t.me/BANJOCTO">
                  <img src={icon01} alt="picture"></img>
                </a>
              </li>
              <li>
                <a className="btn" href="https://x.com/BANJO_CTO">
                  <img src={icon02} alt="picture"></img>
                </a>
              </li>
              <li>
                <a
                  className="btn"
                  href="https://raydium.io/swap/?inputMint=sol&outputMint=HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump"
                >
                  <img src={icon03} alt="picture"></img>
                </a>
              </li>
              <li>
                <a
                  className="btn"
                  href="https://www.dextools.io/app/en/solana/pair-explorer/CLoGjJcAQdCFSXJ5GSKjqw3EfxKK7hVZvd4cVEW2pbvD?t=1732896268211"
                >
                  <img src={icon04} alt="picture"></img>
                </a>
              </li>
              <li>
                <a
                  className="btn"
                  href="https://solscan.io/token/HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump"
                >
                  <img src={icon05} alt="picture"></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About01;
