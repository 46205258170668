import "./Navbar01.css";

import logo from "./../img/favicon.webp";

function Navbar01() {
  return (
    <header className="Navbar01">
      <ul className="Navbar01-links">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#token">Token</a>
        </li>
        <li>
          <a href="#join">How To Buy</a>
        </li>
        <li>
          <a href="#join">Community</a>
        </li>
      </ul>
    </header>
  );
}

export default Navbar01;
