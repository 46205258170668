import "./Token01.css";

function Token01() {
  return (
    <div className="Token01 section" id="token">
      <div className="container">
        <div className="Token01-box">
          <div>
            <h5>TOKEN</h5>
            <h2>Tokenomics</h2>
            <p>
              $BANJO is a Solana-based token, built on the highly efficient and
              scalable Solana blockchain. This ensures fast and affordable
              transactions, making it a seamless experience for all users.
            </p>
            <div className="btnFlex">
              <a
                href="https://dexscreener.com/solana/HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn01">DEXSCREENER</button>
              </a>
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/CLoGjJcAQdCFSXJ5GSKjqw3EfxKK7hVZvd4cVEW2pbvD?t=1732896268211"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">DEXTOOLS</button>
              </a>
            </div>
          </div>

          <div>
            <div className="ca cartoony">
              <h3>CA:</h3>
              <p>HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump</p>
            </div>
            <div className="cartoony">
              <h3>Symbol:</h3>
              <p>$BANJO</p>
            </div>
            <div className="cartoony">
              <h3>Tax:</h3>
              <p>0%</p>
            </div>
            <div className="cartoony">
              <h3>Supply:</h3>
              <p>1,000,000,000</p>
            </div>
            <div className="cartoony">
              <h3>LP:</h3>
              <p>Locked</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token01;
