import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import Preloader01 from "./components/Preloader01";

import Navbar01 from "./components/Navbar01";
import Hero01 from "./components/Hero01";
import Memes01 from "./components/Memes01";
import About01 from "./components/About01";
import Why01 from "./components/Why01";
import Token01 from "./components/Token01";
import HowTo01 from "./components/HowTo01";
import Join01 from "./components/Join01";
import Footer01 from "./components/Footer01";

import pic01 from "./img/bg/bg-frog.webp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Preloader01 />
    <Navbar01 />
    <Hero01 />
    <Memes01 />
    <About01 />
    <Why01 />
    <Token01 />
    <HowTo01 />
    <Join01 />
    <img className="bg-frog" src={pic01} alt="picture"></img>
  </React.StrictMode>
);
