import "./HowTo01.css";

function HowTo01() {
  return (
    <div className="HowTo01 section">
      <div className="container">
        <div className="HowTo01-description">
          <h2>How To Buy ?</h2>
          <p>
            Purchasing $BANJO is easy! Simply use a Solana-compatible wallet
            like Phantom or Slope to connect to a decentralized exchange (DEX)
            like Raydium or Orca. Search for $BANJO, and follow the on-screen
            instructions to complete your purchase.
          </p>
        </div>

        <div className="HowTo01-box">
          <div className="cartoony">
            <h3>Step 1</h3>
            <p>Copy the Contract address from above, or find it on:</p>
            <a
              href="https://solscan.io/token/HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">SOLSCAN</button>
            </a>
          </div>
          <div className="cartoony">
            <h3>Step 2</h3>
            <p>Go to a DEX like Raydium and paste the CA there.</p>
            <a
              href="https://raydium.io/swap/?inputMint=sol&outputMint=HmRpmbeGosahTzChmTmKzFHR5SmgSgXFAXKumajgpump"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">RAYDIUM</button>
            </a>
          </div>
          <div className="cartoony">
            <h3>Step 3</h3>
            <p>
              Make sure you got the right token seleted, then choose the amount
              of SOL you want to spend and click Confirm.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo01;
