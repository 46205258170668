import "./Preloader01.css";
import pic01 from "../img/loaderFrog.webp";

import music from "./../music/i-found-frog-3.mp3";

function Preloader01() {
  function play() {
    new Audio(music).play();

    document.getElementById("preloader").style.display = "none";
  }

  return (
    <div className="Preloader01 section" id="preloader">
      <div className="container">
        <div className="Preloader01-box">
          <img src={pic01} alt="logo"></img>

          <button id="autoplayButton" className="btn01" onClick={play}>
            ENTER
          </button>
        </div>
      </div>
    </div>
  );
}

export default Preloader01;
