import "./Memes01.css";
import meme01 from "../img/memes/1.webp";
import meme02 from "../img/memes/2.webp";
import meme03 from "../img/memes/3.webp";
import meme04 from "../img/memes/4.webp";

import meme06 from "../img/memes/6.webp";
import meme07 from "../img/memes/7.webp";
import meme08 from "../img/memes/8.webp";
import meme09 from "../img/memes/9.webp";
import meme10 from "../img/memes/10.webp";

function Memes01() {
  return (
    <div className="Memes01 section">
      <div className="Memes01-box">
        <img src={meme01} alt="meme 01"></img>
        <img src={meme02} alt="meme 01"></img>
        <img src={meme03} alt="meme 01"></img>
        <img src={meme04} alt="meme 01"></img>

        <img src={meme06} alt="meme 01"></img>
        <img src={meme07} alt="meme 01"></img>
        <img src={meme08} alt="meme 01"></img>
        <img src={meme09} alt="meme 01"></img>
        <img src={meme10} alt="meme 01"></img>

        <img src={meme01} alt="meme 01"></img>
        <img src={meme02} alt="meme 01"></img>
        <img src={meme03} alt="meme 01"></img>
        <img src={meme04} alt="meme 01"></img>

        <img src={meme06} alt="meme 01"></img>
        <img src={meme07} alt="meme 01"></img>
        <img src={meme08} alt="meme 01"></img>
        <img src={meme09} alt="meme 01"></img>
        <img src={meme10} alt="meme 01"></img>
      </div>
    </div>
  );
}

export default Memes01;
