import "./Join01.css";
import pic01 from "../img/frog-05.webp";

function Join01() {
  return (
    <div className="Join01 section" id="join">
      <div className="container">
        <div className="Join01-box cartoony">
          <h2>Join our Comunity!</h2>
          <p>
            Connect with fellow Banjeritos and stay up-to-date on the latest
            news and developments by joining our vibrant community. Follow us on
            social media, join our Telegram chat, and participate in our
            community competitions. Let's hop into the future of memes together!
          </p>

          <div className="btnFlex">
            <a
              href="https://x.com/BANJO_CTO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn01">X/TWITTER</button>
            </a>
            <a
              href="https://t.me/BANJOCTO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn02">TELEGRAM</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join01;
