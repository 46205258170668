import "./Why01.css";

import pic01 from "../img/frogstar.webp";
import pic02 from "../img/frogoku.webp";
import pic03 from "../img/frogonaut.webp";

function Why01() {
  return (
    <div className="Why01 section">
      <div className="container">
        <div className="Why01-box">
          <div className="cartoony">
            <img src={pic03} alt="picture"></img>
            <h3>Community Takeover</h3>
            <p>
              Banjo is owned, supported, and powered by the community, and the
              community alone.
            </p>
          </div>
          <div className="cartoony">
            <img src={pic01} alt="picture"></img>
            <h3>Locked LP Forever</h3>
            <p>
              Since the token was launched via pump.fun, the liquidity pool is
              locked and to be never touched forever and ever.
            </p>
          </div>
          <div className="cartoony">
            <img src={pic02} alt="picture"></img>
            <h3>Driven by Holders</h3>
            <p>
              $BANJO has a huge support group of whales, dolphins, and jellies.
              We look to grow together.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why01;
