import "./Footer01.css";

function Footer01() {
  return (
    <header className="Footer01">
      <div className="Footer01-logo">
        <img src="" alt="logo"></img>
        <p>Token name @2024</p>
      </div>

      <ul className="Footer01-links">
        <li>
          <a href="/">Disclaimer.</a>
        </li>
      </ul>
    </header>
  );
}

export default Footer01;
